// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TripList = require("../../../models/TripList.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var TripListsCollections = require("./TripListsCollections.bs.js");

function TripListsCollections__JsBridge$default(props) {
  return React.createElement(TripListsCollections.make, {
              collections: Json_decode.array(TripList.Collection.fromJson, props.collections)
            });
}

var $$default = TripListsCollections__JsBridge$default;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
