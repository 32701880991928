// @flow
import React from 'react';
import cn from 'classnames';

import * as Amplitude from 'app/rescript/libs/Amplitude.bs.js';
import { buildImageUrlFromJs as buildImageUrl } from 'app/rescript/libs/AwsImageHandlerV5.bs.js';

import { FormattedMessage } from 'react-intl';
import css from './TrustBanner.scss';

const TRUST_PARTNERS = [
  {
    url:
      'https://www.entrepreneur.com/starting-a-business/he-built-a-platform-to-find-you-the-best-vacation-rental/477832',
    imgHref: 'images/static/welcome/entrepreneur_logo.webp',
    imgWidth: 133,
    imgHeight: 51,
    smallScreenImgWidth: 106,
    smallScreenImgHeight: 40,
    alt: 'Entrepreneur',
    colorful: true,
    classNameLink: 'tw-mr-[23px] md:tw-mr-[39px] lg:tw-mr-[14px] xl:tw-mr-[17px]',
  },
  {
    url: 'https://ktla.com/news/travel-sites-to-take-your-trip-to-the-next-level/',
    imgHref: 'images/static/welcome/ktla5.webp',
    imgWidth: 133,
    imgHeight: 50,
    smallScreenImgWidth: 107,
    smallScreenImgHeight: 40,
    alt: 'KTLA5',
    colorful: true,
    classNameLink: 'tw-mr-[23px] md:tw-mr-[39px] lg:tw-mr-[14px] xl:tw-mr-[17px]',
  },
  {
    url: 'https://www.foxnews.com/tech/how-compare-vacation-rental-home-prices',
    imgHref: 'images/static/welcome/fox_news_channel_colorful_logo.webp',
    imgWidth: 60,
    imgHeight: 60,
    smallScreenImgWidth: 40,
    smallScreenImgHeight: 40,
    alt: 'Fox News',
    classNameLink: 'md:tw-mr-[39px] lg:tw-mr-[14px] xl:tw-mr-[17px]',
  },
  {
    url:
      'https://www.forbes.com/sites/christopherelliott/2022/11/20/want-to-really-get-away-heres-some-expert-advice-for-digital-nomad-accommodations/',
    imgHref: 'images/static/welcome/forbes_colorful_logo.webp',
    imgWidth: 147,
    imgHeight: 40,
    smallScreenImgWidth: 111,
    smallScreenImgHeight: 30,
    alt: 'Forbes',
    classNameLink: 'md:tw-mr-[39px] lg:tw-mr-[14px] xl:tw-mr-[17px]',
  },
  {
    url:
      'https://www.usatoday.com/story/travel/columnist/2024/04/26/vacation-rental-sold-cancellation/73368813007/',
    imgHref: 'images/static/welcome/usa_today_logo.webp',
    imgWidth: 114,
    imgHeight: 60,
    smallScreenImgWidth: 86,
    smallScreenImgHeight: 45,
    alt: 'USA Today',
    classNameLink: 'tw-mr-[23px] md:tw-mr-[30px] lg:tw-mr-[14px] xl:tw-mr-[17px]',
  },
  {
    url: 'https://www.washingtonpost.com/travel/tips/airbnb-vrbo-rental-scams/',
    imgHref: 'images/static/homepage-wp-vector-logo.webp',
    imgWidth: 210,
    imgHeight: 30,
    smallScreenImgWidth: 210,
    smallScreenImgHeight: 30,
    alt: 'Washington Post',
    classNameLink: 'md:tw-mr-[32px] lg:tw-mr-0 xl:tw-mr-[17px]',
  },
  {
    url: 'https://www.aarp.org/money/budgeting-saving/info-2023/99-great-ways-to-save.html',
    imgHref: 'images/static/welcome/arrb_logo_homepage.webp',
    imgWidth: 160,
    imgHeight: 40,
    smallScreenImgWidth: 120,
    smallScreenImgHeight: 30,
    alt: 'AARP',
    colorful: true,
    classNameLink: 'tw-mr-[13px] md:tw-mr-[32px] lg:tw-mr-[24px] xl:tw-mr-[17px]',
  },
  {
    url: 'https://richontech.tv/p/lesser-known-travel-websites-to-take',
    imgHref: 'images/static/welcome/rich_on_tech_logo.webp',
    imgWidth: 60,
    imgHeight: 60,
    smallScreenImgWidth: 40,
    smallScreenImgHeight: 40,
    alt: 'Rich on Tech',
    colorful: true,
    classNameLink: 'md:tw-mr-[32px] lg:tw-mr-[24px]',
  },
  {
    url: 'https://www.cardrates.com/news/hichee-can-save-travelers-both-time-and-money/',
    imgHref: 'images/static/welcome/card_rates_colorful_logo.webp',
    imgWidth: 129,
    imgHeight: 60,
    smallScreenImgWidth: 86,
    smallScreenImgHeight: 40,
    alt: 'Card Rates',
    colorful: true,
    classNameImage: css.trustPartnerImageCardRates,
    classNameLink: 'md:tw-mr-[32px] lg:tw-mr-[24px]',
  },
  {
    url: 'https://cyberguy.com/travel/how-to-compare-vacation-rental-home-prices/',
    imgHref: 'images/static/welcome/ciberguy_logo.webp',
    imgWidth: 75,
    imgHeight: 60,
    smallScreenImgWidth: 50,
    smallScreenImgHeight: 40,
    alt: 'Cyber Guy',
    colorful: true,
    classNameLink: 'lg:tw-mr-[24px]',
  },
  {
    url:
      'https://www.elliott.org/answers/how-to-rent-vacation-home-ultimate-guide/#h-how-do-i-find-and-rent-a-vacation-home',
    imgHref: 'images/static/welcome/elliott_report.webp',
    imgWidth: 124,
    imgHeight: 60,
    smallScreenImgWidth: 124,
    smallScreenImgHeight: 60,
    alt: 'Elliott Report',
    classNameLink: 'tw-hidden lg:tw-flex',
  },
];

const TrustBanner = () => {
  const handlePartnerClick = (name: string) => {
    Amplitude.logEvent('Featured tile clicked', {
      value: name,
    });
  };

  return (
    <div id="trust-banner" className={cn(css.sectionWrapperTrustPartners, css.column)}>
      <div className="tw-font-semibold tw-text-[21px] tw-leading-[32px] tw-text-neutral-gray md:tw-text-[24px] md:tw-text-leading-[36px] lg:tw-text-[32px] lg:tw-leading-[42px]">
        <FormattedMessage id="trust-banner.title" defaultMessage="HiChee in the News" />
      </div>
      <div className={css.trustPartnersWrapper}>
        {TRUST_PARTNERS.map(partner => (
          <a
            key={partner.url}
            onClick={() => handlePartnerClick(partner.alt)}
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
            className={cn([css.trustPartnerLink, partner.classNameLink ? partner.classNameLink : ''])}
          >
            <picture>
              <source
                srcSet={`
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth,
                      height: partner.imgHeight,
                    })},
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 2,
                      height: partner.imgHeight * 2,
                    })} 2x,
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 3,
                      height: partner.imgHeight * 3,
                    })} 3x,
                  `}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth,
                      height: partner.smallScreenImgHeight,
                    })},
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth * 2,
                      height: partner.smallScreenImgHeight * 2,
                    })} 2x,
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth * 3,
                      height: partner.smallScreenImgHeight * 3,
                    })} 3x,
                  `}
              />
              <img
                className={partner.classNameImage ? partner.classNameImage : ''}
                src={buildImageUrl({ url: partner.imgHref })}
                alt={partner.alt}
                loading="lazy"
              />
            </picture>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TrustBanner;
