// @flow

import * as React from 'react';
import Youtube from 'react-youtube';

import css from './index.scss';

type Props = {
  videoId: string,
  opts: Object,
};

const Video = ({ videoId, opts }: Props) => (
  <div className={css.videoWrapper}>
    <Youtube opts={opts} videoId={videoId} />
  </div>
);

export default Video;
