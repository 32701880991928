/* @flow */

import * as React from 'react';
import { Provider } from 'react-redux';

import type { RailsContext, ImpersonatedUser } from 'types';
import type { NavbarProps, Alert, Collection } from 'layout/types';

import IntlProviderWrapper from 'app/rescript/i18n/IntlProviderWrapper.bs.js';
import ScreenSizeContext from 'app/rescript/contexts/ScreenSizeContext.bs.js';
import AdBlockContext from 'app/rescript/contexts/AdBlockContext.bs.js';

import Layout from 'layout';
import WelcomePageContainer from './containers/WelcomePageContainer';

import { initStore, sagaMiddleware, rootSaga } from './store';

export type RailsProps = {|
  navbar: NavbarProps,
  alerts: Array<Alert>,
  impersonatedUser: ImpersonatedUser | null,
  contextualListingsPath: string,
  slug: string | null,
  fansVideos?: Array<string>,
  collections: Array<Collection>,
|};

const WelcomePageLayout = (props: RailsProps, context: RailsContext) => {
  const store = initStore(props, context);

  sagaMiddleware.run(rootSaga);

  return () => (
    <Provider store={store}>
      <IntlProviderWrapper>
        <ScreenSizeContext>
          <AdBlockContext>
            <Layout
              navbar={props.navbar}
              alerts={props.alerts}
              impersonatedUser={props.impersonatedUser}
              showTagline
              hideSearch
            >
              {/* $FlowIgnoreMe */}
              <WelcomePageContainer {...{ ...props, ...context }} />
            </Layout>
          </AdBlockContext>
        </ScreenSizeContext>
      </IntlProviderWrapper>
    </Provider>
  );
};

export default WelcomePageLayout;
