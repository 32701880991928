// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var locations = [
  {
    name: "Alabama",
    url: "/vacation-rentals/usa/alabama",
    imageUrl: "images/static/welcome/alabama.webp"
  },
  {
    name: "Alaska",
    url: "/vacation-rentals/usa/alaska",
    imageUrl: "images/static/welcome/alaska.webp"
  },
  {
    name: "Arizona",
    url: "/vacation-rentals/usa/arizona",
    imageUrl: "images/static/welcome/arizona.webp"
  },
  {
    name: "Arkansas",
    url: "/vacation-rentals/usa/arkansas",
    imageUrl: "images/static/welcome/arkansas.webp"
  },
  {
    name: "California",
    url: "/vacation-rentals/usa/california",
    imageUrl: "images/static/welcome/california.webp"
  },
  {
    name: "Colorado",
    url: "/vacation-rentals/usa/colorado",
    imageUrl: "images/static/welcome/colorado.webp"
  },
  {
    name: "Connecticut",
    url: "/vacation-rentals/usa/connecticut",
    imageUrl: "images/static/welcome/connecticut.webp"
  },
  {
    name: "Delaware",
    url: "/vacation-rentals/usa/delaware",
    imageUrl: "images/static/welcome/delaware.webp"
  },
  {
    name: "Florida",
    url: "/vacation-rentals/usa/florida",
    imageUrl: "images/static/welcome/florida.webp"
  },
  {
    name: "Georgia",
    url: "/vacation-rentals/usa/georgia",
    imageUrl: "images/static/welcome/georgia.webp"
  },
  {
    name: "Hawaii",
    url: "/vacation-rentals/usa/hawaii",
    imageUrl: "images/static/welcome/hawaii.webp"
  },
  {
    name: "Idaho",
    url: "/vacation-rentals/usa/idaho",
    imageUrl: "images/static/welcome/idaho.webp"
  },
  {
    name: "Illinois",
    url: "/vacation-rentals/usa/illinois",
    imageUrl: "images/static/welcome/illinois.webp"
  },
  {
    name: "Indiana",
    url: "/vacation-rentals/usa/indiana",
    imageUrl: "images/static/welcome/indiana.webp"
  },
  {
    name: "Iowa",
    url: "/vacation-rentals/usa/iowa",
    imageUrl: "images/static/welcome/iowa.webp"
  },
  {
    name: "Kansas",
    url: "/vacation-rentals/usa/kansas",
    imageUrl: "images/static/welcome/kansas.webp"
  },
  {
    name: "Kentucky",
    url: "/vacation-rentals/usa/kentucky",
    imageUrl: "images/static/welcome/kentucky.webp"
  },
  {
    name: "Louisiana",
    url: "/vacation-rentals/usa/louisiana",
    imageUrl: "images/static/welcome/louisiana.webp"
  },
  {
    name: "Maine",
    url: "/vacation-rentals/usa/maine",
    imageUrl: "images/static/welcome/maine.webp"
  },
  {
    name: "Maryland",
    url: "/vacation-rentals/usa/maryland",
    imageUrl: "images/static/welcome/maryland.webp"
  },
  {
    name: "Massachusetts",
    url: "/vacation-rentals/usa/massachusetts",
    imageUrl: "images/static/welcome/massachusetts.webp"
  },
  {
    name: "Michigan",
    url: "/vacation-rentals/usa/michigan",
    imageUrl: "images/static/welcome/michigan.webp"
  },
  {
    name: "Minnesota",
    url: "/vacation-rentals/usa/minnesota",
    imageUrl: "images/static/welcome/minnesota.webp"
  },
  {
    name: "Mississippi",
    url: "/vacation-rentals/usa/mississippi",
    imageUrl: "images/static/welcome/mississippi.webp"
  },
  {
    name: "Missouri",
    url: "/vacation-rentals/usa/missouri",
    imageUrl: "images/static/welcome/missouri.webp"
  },
  {
    name: "Montana",
    url: "/vacation-rentals/usa/montana",
    imageUrl: "images/static/welcome/montana.webp"
  },
  {
    name: "Nebraska",
    url: "/vacation-rentals/usa/nebraska",
    imageUrl: "images/static/welcome/nebraska.webp"
  },
  {
    name: "Nevada",
    url: "/vacation-rentals/usa/nevada",
    imageUrl: "images/static/welcome/nevada.webp"
  },
  {
    name: "New Hampshire",
    url: "/vacation-rentals/usa/new-hampshire",
    imageUrl: "images/static/welcome/new-hampshire.webp"
  },
  {
    name: "New Jersey",
    url: "/vacation-rentals/usa/new-jersey",
    imageUrl: "images/static/welcome/new-jersey.webp"
  },
  {
    name: "New Mexico",
    url: "/vacation-rentals/usa/new-mexico",
    imageUrl: "images/static/welcome/new-mexico.webp"
  },
  {
    name: "New York",
    url: "/vacation-rentals/usa/new-york",
    imageUrl: "images/static/welcome/new-york.webp"
  },
  {
    name: "North Carolina",
    url: "/vacation-rentals/usa/north-carolina",
    imageUrl: "images/static/welcome/north-carolina.webp"
  },
  {
    name: "North Dakota",
    url: "/vacation-rentals/usa/north-dakota",
    imageUrl: "images/static/welcome/north-dakota.webp"
  },
  {
    name: "Ohio",
    url: "/vacation-rentals/usa/ohio",
    imageUrl: "images/static/welcome/ohio.webp"
  },
  {
    name: "Oklahoma",
    url: "/vacation-rentals/usa/oklahoma",
    imageUrl: "images/static/welcome/oklahoma.webp"
  },
  {
    name: "Oregon",
    url: "/vacation-rentals/usa/oregon",
    imageUrl: "images/static/welcome/oregon.webp"
  },
  {
    name: "Pennsylvania",
    url: "/vacation-rentals/usa/pennsylvania",
    imageUrl: "images/static/welcome/pennsylvania.webp"
  },
  {
    name: "Rhode Island",
    url: "/vacation-rentals/usa/rhode-island",
    imageUrl: "images/static/welcome/rhode-island.webp"
  },
  {
    name: "South Carolina",
    url: "/vacation-rentals/usa/south-carolina",
    imageUrl: "images/static/welcome/south-carolina.webp"
  },
  {
    name: "South Dakota",
    url: "/vacation-rentals/usa/south-dakota",
    imageUrl: "images/static/welcome/south-dakota.webp"
  },
  {
    name: "Tennessee",
    url: "/vacation-rentals/usa/tennessee",
    imageUrl: "images/static/welcome/tennessee.webp"
  },
  {
    name: "Texas",
    url: "/vacation-rentals/usa/texas",
    imageUrl: "images/static/welcome/texas.webp"
  },
  {
    name: "Utah",
    url: "/vacation-rentals/usa/utah",
    imageUrl: "images/static/welcome/utah.webp"
  },
  {
    name: "Vermont",
    url: "/vacation-rentals/usa/vermont",
    imageUrl: "images/static/welcome/vermont.webp"
  },
  {
    name: "Virginia",
    url: "/vacation-rentals/usa/virginia",
    imageUrl: "images/static/welcome/virginia.webp"
  },
  {
    name: "Washington",
    url: "/vacation-rentals/usa/washington",
    imageUrl: "images/static/welcome/washington.webp"
  },
  {
    name: "West Virginia",
    url: "/vacation-rentals/usa/west-virginia",
    imageUrl: "images/static/welcome/west-virginia.webp"
  },
  {
    name: "Wisconsin",
    url: "/vacation-rentals/usa/wisconsin",
    imageUrl: "images/static/welcome/wisconsin.webp"
  },
  {
    name: "Wyoming",
    url: "/vacation-rentals/usa/wyoming",
    imageUrl: "images/static/welcome/wyoming.webp"
  }
];

exports.locations = locations;
/* No side effect */
