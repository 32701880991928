// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Svg = require("../../../styleguide/components/Svg/Svg.bs.js");
var React = require("react");
var Colors = require("../../../styleguide/styles/Colors.bs.js");

function BackgroundVector2(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "";
  return React.createElement(Svg.make, {
              viewBoxWidth: "308",
              viewBoxHeight: "181",
              className: className$1,
              children: React.createElement("path", {
                    d: "M1 324.92c137.67 3.16 413 28.5 413-144.93C414-15.58 138.67-2.17 1 8",
                    fill: "none",
                    stroke: Colors.primary,
                    strokeWidth: "5"
                  })
            });
}

var make = BackgroundVector2;

exports.make = make;
/* Svg Not a pure module */
