// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Svg = require("../../../styleguide/components/Svg/Svg.bs.js");
var React = require("react");
var Colors = require("../../../styleguide/styles/Colors.bs.js");

function BackgroundVector1(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "";
  return React.createElement(Svg.make, {
              viewBoxWidth: "417",
              viewBoxHeight: "177",
              className: className$1,
              children: React.createElement("path", {
                    d: "M416 171C278.33 174.17 3 199.5 3 26.08 3-169.5 95.83 42.33 233.5 52.5",
                    fill: "none",
                    stroke: Colors.primary,
                    strokeWidth: "5"
                  })
            });
}

var make = BackgroundVector1;

exports.make = make;
/* Svg Not a pure module */
