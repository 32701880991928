// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ShakaCodeDataAd = require("./ShakaCodeDataAd.bs.js");

var $$default = ShakaCodeDataAd.make;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* ShakaCodeDataAd Not a pure module */
