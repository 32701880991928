// @flow

// $FlowFixMe
import * as React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as Amplitude from 'app/rescript/libs/Amplitude.bs.js';
import { buildImageUrlFromJs as buildImageUrl } from 'app/rescript/libs/AwsImageHandlerV5.bs.js';
import { Image } from 'libs/components';
import AirbnbIcon from 'rescript/styleguide/icons/AirbnbIcon.bs.js';
import PlayIcon from 'rescript/styleguide/icons/PlayIcon.bs.js';
import VerifiedIcon from 'rescript/styleguide/icons/VerifiedIcon.bs.js';
import VrboIcon from 'rescript/styleguide/icons/VrboIcon.bs.js';
import GoogleAd from 'app/rescript/styleguide/components/GoogleAd/GoogleAd__JsBridge.bs.js';
import ShakaCodeDataAd from 'app/rescript/styleguide/components/ShakaCodeDataAd/ShakaCodeDataAd__JsBridge.bs.js';
import ExtensionPromoCard from 'rescript/styleguide/components/ExtensionPromoCard/ExtensionPromoCard.bs.js';
import TripListsPromoCard from 'rescript/styleguide/components/TripListsPromoCard/TripListsPromoCard.bs.js';
import FansReviews from 'app/rescript/styleguide/components/FansReviews/FansReviews__JsBridge.bs.js';
import ButtonWithIcon from 'app/styleguide/components/Button/ButtonWithIcon/ButtonWithIcon';
import Modal from 'app/styleguide/components/Modal/Modal';
import Video from 'app/styleguide/components/Video';
import css from './HicheeDifferent.scss';

type Props = {
  desktop: boolean,
  mobile: boolean,
  fansVideos?: Array<string>,
};

const PriceWithIcon = ({ icon, host, price }) => (
  <div className={css.priceWrapper}>
    {icon}
    <div className={css.priceTextWrapper}>
      <div className={css.hostName}>{host}</div>
      <div className={css.price}>{price}</div>
    </div>
  </div>
);

const SectionImage = ({
  url,
  alt,
  width,
  height,
}: {
  url: string,
  alt: string,
  width?: string,
  height?: string,
}) => (
  <Image
    alt={alt}
    url={url}
    dpr="auto"
    crop="fit"
    width={width}
    height={height}
    loading="lazy"
    className={css.sectionImage}
  />
);

const VideoThumbnail = ({ url, alt }: { url: string, alt: string }) => (
  <Image
    alt={alt}
    url={url}
    dpr="auto"
    crop="fit"
    // Set the width and height the same as in the CSS `videoThumbnail` class
    width="465"
    height="319"
    loading="lazy"
    className={css.videoThumbnail}
  />
);

const SaveMoneySection = ({ text }: { text: string }) => (
  <div className={css.saveSectionWrapper}>
    <div className={css.saveSectionText}>{text}</div>
  </div>
);

const PlayButtonIcon = () => <PlayIcon title="Play Video" size="LG" color="White" />;

const TextSection = ({ heading, text }) => (
  <div className={css.textWrapper}>
    <div className={css.sectionHeading}>{heading}</div>
    <p className={css.sectionText}>{text}</p>
  </div>
);

const HicheeDifferent = (props: Props) => {
  const [showFirstModal, setShowFirstModal] = React.useState(false);

  const handleVideoClick = (value: string) => {
    Amplitude.logEvent('Homepage video clicked', {
      value,
    });
  };

  const handleShowFirstModal = () => {
    handleVideoClick('Compare Prices and Reviews Video');
    setShowFirstModal(true);
  };

  const hideFirstModal = () => setShowFirstModal(false);

  const [showSecondModal, setShowSecondModal] = React.useState(false);

  const handleShowSecondModal = () => {
    handleVideoClick('Magic Search Video');
    setShowSecondModal(true);
  };

  const hideSecondModal = () => setShowSecondModal(false);

  return (
    <div className={css.wrapper}>
      <div
        className={css.greenShape}
        style={{
          backgroundImage: `url(${buildImageUrl({
            url: 'images/static/homepage-rec-shape-min_k2smbl.webp',
          })})`,
        }}
      >
        {props.fansVideos && <FansReviews fansVideos={props.fansVideos} />}
        <div className={css.saveMoneySection}>
          <p className={css.title}>
            <strong>
              <FormattedMessage
                id="welcome.hichee-different.prices.save-money.title"
                defaultMessage="Save money by using HiChee"
              />
            </strong>
          </p>
          <div className={css.subtitleWrapper}>
            <p className={css.subTitle}>
              <FormattedMessage
                id="welcome.hichee-different.prices.description"
                defaultMessage="Airbnb, Booking.com and Vrbo are great for finding the perfect rental. But, they each charge different prices for the same property and the same dates."
              />
            </p>
            <p className={cn(css.subTitle, css.subTitleMargin)}>
              <FormattedMessage
                id="welcome.hichee-save-money.description"
                defaultMessage="We help YOU SAVE MONEY by showing the best prices for each property and where best to book it."
              />
            </p>
          </div>
        </div>
        <div className={css.bannersWrapper}>
          <ExtensionPromoCard location="HomePage" />
          <TripListsPromoCard location="HomePage" />
        </div>
        <div className={css.sectionWrapper}>
          <div className={cn(css.sectionExampleWrapper)}>
            <div className={cn(css.imageWrapper, css.imageWrapperMap)}>
              <Image
                alt="star rating"
                url="images/static/homepage-listing-rating-01.webp"
                loading="lazy"
                className={css.reviewScoreIcon}
              />
              <ButtonWithIcon
                className={css.playButtonWrapper}
                size="md"
                Icon={PlayButtonIcon}
                iconColor="white"
                onClick={handleShowFirstModal}
              />
              <div className={css.watchVideo}>
                <span>Watch Video</span>
              </div>
              <VideoThumbnail alt="Beach Escape! Spacious..." url="images/static/homepage-listing-02.webp" />
              <Modal
                className={css.videoModal}
                dialogClassName={css.modalDialog}
                show={showFirstModal}
                onHide={hideFirstModal}
              >
                <Video
                  className={css.modalVideo}
                  videoId="MZiY4_60UGI"
                  opts={props.mobile ? { height: '160', width: '290' } : { height: '400', width: '600' }}
                />
              </Modal>
            </div>
            <div className={css.pricesWrapper}>
              <PriceWithIcon
                icon={<AirbnbIcon size="XS" className={css.priceIcon} />}
                host="Airbnb"
                price="$1000"
              />
              <PriceWithIcon
                icon={<VrboIcon size="XS" className={css.priceIcon} />}
                host="Vrbo"
                price="$800"
              />
              <PriceWithIcon
                icon={<VerifiedIcon size="XS" color="Green" className={css.priceIcon} />}
                host="Katherine Mcnamara"
                price="$700"
              />
              <SaveMoneySection text="Save $300 (30%) vs Airbnb" />
            </div>
          </div>
          <TextSection
            heading="Search by Listing Link/ID. Compare Prices and Reviews"
            text="In our search box, just paste in the Listing Link or ID from Airbnb, Booking.com or Vrbo.  With one click, you’ll see the best prices and reviews, all on one page."
          />
        </div>
        <div className={css.sectionWrapperWithImageOnRight}>
          <TextSection
            heading="Travel Hack: Magic Search"
            text="Our Magic Search uses property images and more to help you look for the host or owner’s website, so you can book direct for even bigger savings."
          />
          <div className={css.imageWrapper}>
            <ButtonWithIcon
              className={css.playButtonWrapper}
              size="md"
              color="primary"
              Icon={PlayButtonIcon}
              iconColor="white"
              onClick={handleShowSecondModal}
            />
            <div className={cn(css.watchVideo, css.watchVideoSearchSection)}>
              <span>Watch Video</span>
            </div>
            <Image
              alt="Search listings"
              url="images/static/homepage-listing-01.webp"
              // Set the width and height the same as in the CSS `magicSearchBackground` class
              width="465"
              height="339"
              loading="lazy"
              className={cn(css.magicSearchBackground, css.sectionImage)}
            />
            <Image
              alt="Search listings Text"
              url="images/static/homepage-magic-search-01.webp"
              loading="lazy"
              className={css.magicSearchText}
            />
            <Modal
              className={css.videoModal}
              dialogClassName={css.modalDialog}
              show={showSecondModal}
              onHide={hideSecondModal}
            >
              <Video
                className={css.modalVideo}
                videoId="ZNhj6n__V7Q"
                opts={props.mobile ? { height: '160', width: '290' } : { height: '400', width: '600' }}
              />
            </Modal>
          </div>
        </div>
        <div className={css.sectionWrapper}>
          <div className={cn(css.imageWrapper, css.imageWrapperMap)}>
            <SectionImage
              alt="Map with prices"
              url="images/static/static-map-multiple-providers.webp"
              // Set the width and height the same as in the CSS `sectionImage` class
              width={props.desktop ? '350' : undefined}
              height={props.desktop ? '320' : undefined}
            />
          </div>
          <TextSection
            heading="All Properties on One Map"
            text="See all area properties and their best prices in a single HiChee map search, No need to bounce back and forth between Airbnb, Booking.com, and Vrbo."
          />
        </div>
        <div className={cn(css.sectionWrapper, 'tw-flex tw-flex-col tw-gap-y-[48px]')}>
          <GoogleAd
            slot="2821768523"
            format="auto"
            fullWidthResponsive
            wrapperClassName={css.homeBottomAdDesktop}
            restrictTo="desktop-only"
          />
          <GoogleAd
            slot="3456193346"
            format="auto"
            fullWidthResponsive
            wrapperClassName={css.homeBottomAdMobile}
            restrictTo="mobile-only"
          />
          <ShakaCodeDataAd />
        </div>
      </div>
    </div>
  );
};

export default HicheeDifferent;
