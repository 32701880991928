// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Cx = require("rescript-classnames/src/Cx.bs.js");
var React = require("react");
var WithTestId = require("../WithTestId/WithTestId.bs.js");

function Span(props) {
  var className = props.className;
  var size = props.size;
  var fontWeight = props.fontWeight;
  var fontWeight$1 = fontWeight !== undefined ? fontWeight : /* Regular */0;
  var size$1 = size !== undefined ? size : /* MD */1;
  var className$1 = className !== undefined ? className : "";
  var tmp;
  switch (size$1) {
    case /* SM */0 :
        tmp = "tw-text-[12px] tw-leading-[18px]";
        break;
    case /* MD */1 :
        tmp = "tw-text-[14px] tw-leading-[21px]";
        break;
    case /* LG */2 :
        tmp = "tw-text-[16px] tw-leading-[24px]";
        break;
    
  }
  var tmp$1;
  switch (fontWeight$1) {
    case /* Regular */0 :
        tmp$1 = "tw-font-normal";
        break;
    case /* Semibold */1 :
        tmp$1 = "tw-font-semibold";
        break;
    case /* Medium */2 :
        tmp$1 = "tw-font-medium";
        break;
    
  }
  return React.createElement(WithTestId.Optional.make, {
              id: props.testId,
              children: React.createElement("span", {
                    className: Cx.cx([
                          "tw-m-0",
                          tmp,
                          tmp$1,
                          className$1
                        ])
                  }, props.children)
            });
}

var make = Span;

exports.make = make;
/* react Not a pure module */
