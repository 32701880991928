// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var FansReviews = require("./FansReviews.bs.js");

function FansReviews__JsBridge$default(props) {
  return React.createElement(FansReviews.make, {
              fansVideos: props.fansVideos
            });
}

var $$default = FansReviews__JsBridge$default;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
