// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function PlayIcon(props) {
  var title = props.title;
  var title$1 = title !== undefined ? title : "Play";
  return React.createElement(Icon.make, {
              title: title$1,
              size: props.size,
              margin: props.margin,
              className: props.className,
              children: React.createElement("path", {
                    d: "M2.36205 1.16216C2.36205 0.571978 3.01965 0.219834 3.51087 0.546958L13.4251 7.14929C13.8643 7.44183 13.8643 8.08717 13.4251 8.37972L3.51087 14.9821C3.01965 15.3092 2.36205 14.957 2.36205 14.3669V1.16216Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = PlayIcon;

var $$default = PlayIcon;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Icon Not a pure module */
