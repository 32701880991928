// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function HouseIcon(props) {
  var color = props.color;
  var size = props.size;
  var title = props.title;
  var title$1 = title !== undefined ? title : "Direct to Host";
  var size$1 = size !== undefined ? size : "SM";
  var color$1 = color !== undefined ? color : Icon.defaultColor;
  return React.createElement(Icon.make, {
              title: title$1,
              size: size$1,
              margin: props.margin,
              className: props.className,
              children: null
            }, React.createElement("path", {
                  style: Icon.style(color$1),
                  d: "M7.99977 2.22512L14.3992 8.62458V13.1117C14.3992 13.536 14.2307 13.9429 13.9306 14.2429C13.6306 14.543 13.2237 14.7115 12.7994 14.7115H3.20018C2.77587 14.7115 2.36893 14.543 2.0689 14.2429C1.76887 13.9429 1.60031 13.536 1.60031 13.1117V8.62458L7.99977 2.22512V2.22512ZM13.3327 1.37933V5.11235L11.1995 2.97919V1.37933C11.1995 1.23789 11.2557 1.10225 11.3557 1.00224C11.4557 0.902225 11.5914 0.846039 11.7328 0.846039H12.7994C12.9408 0.846039 13.0764 0.902225 13.1765 1.00224C13.2765 1.10225 13.3327 1.23789 13.3327 1.37933Z"
                }), React.createElement("path", {
                  style: Icon.style(color$1),
                  d: "M7.24593 0.312279C7.44594 0.112327 7.71718 0 8 0C8.28282 0 8.55406 0.112327 8.75407 0.312279L15.8436 7.40075C15.9437 7.50089 16 7.6367 16 7.77832C16 7.91993 15.9437 8.05575 15.8436 8.15589C15.7435 8.25602 15.6077 8.31228 15.466 8.31228C15.3244 8.31228 15.1886 8.25602 15.0885 8.15589L8 1.06635L0.91153 8.15589C0.811393 8.25602 0.675578 8.31228 0.533962 8.31228C0.392346 8.31228 0.256531 8.25602 0.156394 8.15589C0.0562566 8.05575 0 7.91993 0 7.77832C0 7.6367 0.0562566 7.50089 0.156394 7.40075L7.24593 0.312279Z"
                }));
}

var make = HouseIcon;

var $$default = HouseIcon;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Icon Not a pure module */
