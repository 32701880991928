// @flow
import React from 'react';

const WelcomePageStructuredData = () => (
  <>
    <span itemScope itemType="https://schema.org/Organization">
      <meta itemProp="name" content="HiChee" />

      <meta
        itemProp="description"
        content="HiChee.com, a vacation rental comparison site that allows you to find the best selection of rental properties, compare prices from owners and OTAs, and get directly in touch with property managers. The site is fast, attractive, and easy to navigate, offering a great user experience for travelers and property managers alike."
      />
      <meta itemProp="url" content="https://hichee.com" />
      <meta
        itemProp="logo"
        // images/static/hichee-logo-structured-data.webp
        content="https://images.hichee.com/eyJidWNrZXQiOiJoYy1pbWFnZXMtcHJvZCIsImtleSI6ImltYWdlcy9zdGF0aWMvaGljaGVlLWxvZ28tc3RydWN0dXJlZC1kYXRhLndlYnAifQ==?signature=55e59d31333d748c854f5e338c4ae5b106e09bae387db5d53a17dac0991f69e4"
      />
      <meta
        itemProp="image"
        // images/static/hichee-logo-structured-data.webp
        content="https://images.hichee.com/eyJidWNrZXQiOiJoYy1pbWFnZXMtcHJvZCIsImtleSI6ImltYWdlcy9zdGF0aWMvaGljaGVlLWxvZ28tc3RydWN0dXJlZC1kYXRhLndlYnAifQ==?signature=55e59d31333d748c854f5e338c4ae5b106e09bae387db5d53a17dac0991f69e4"
      />
      <meta itemProp="sameAs" content="https://www.facebook.com/hicheerentals/" />
      <meta itemProp="sameAs" content="https://www.tiktok.com/@hi.chee?lang=en" />
      <meta itemProp="sameAs" content="https://www.shakacode.com/hichee/" />
      <meta itemProp="sameAs" content="https://www.youtube.com/channel/UC8GC54Bwmkyhs_aKKvY_I0g" />
      <meta
        itemProp="sameAs"
        content="https://chrome.google.com/webstore/detail/hichee-price-comparison/ikoocbbcgemiakcmeepjmlgipjeeibon"
      />
      <meta itemProp="sameAs" content="https://apps.apple.com/us/app/hichee/id1556724552" />

      <span itemProp="parentOrganization" itemScope itemType="https://schema.org/Organization">
        <meta itemProp="name" content="Shakacode" />
        <meta itemProp="url" content="https://shakacode.com" />
        <meta itemProp="telephone" content="808-281-7272" />
        <meta itemProp="email" content="contact@shakacode.com" />
        <meta
          itemProp="description"
          content="We develop elegant, high-performance mobile and web applications built with Ruby on Rails, React, Gatsby, Reason and related technologies for simplified deployment and accelerated performance."
        />
        <meta
          itemProp="logo"
          // images/static/shakacode-logo-structured-data.webp
          content="https://images.hichee.com/eyJidWNrZXQiOiJoYy1pbWFnZXMtcHJvZCIsImtleSI6ImltYWdlcy9zdGF0aWMvc2hha2Fjb2RlLWxvZ28tc3RydWN0dXJlZC1kYXRhLndlYnAifQ==?signature=3db5e9eef765087e51da52bedb8554ffdf6e29c708c21b33cd6b324eeebfa21c"
        />
        <meta
          itemProp="image"
          // images/static/shakacode-logo-structured-data.webp
          content="https://images.hichee.com/eyJidWNrZXQiOiJoYy1pbWFnZXMtcHJvZCIsImtleSI6ImltYWdlcy9zdGF0aWMvc2hha2Fjb2RlLWxvZ28tc3RydWN0dXJlZC1kYXRhLndlYnAifQ==?signature=3db5e9eef765087e51da52bedb8554ffdf6e29c708c21b33cd6b324eeebfa21c"
        />
        <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
          <meta itemProp="streetAddress" content="141 Makahiki Street" />
          <meta itemProp="postalCode" content="96779" />
          <meta itemProp="addressLocality" content="Paia, HI" />
        </span>
        <span itemProp="founder" itemScope itemType="https://schema.org/Person">
          <meta itemProp="name" content="Justin Gordon" />
          <meta itemProp="email" content="justin@shakacode.com" />
        </span>
        <meta itemProp="sameAs" content="https://www.linkedin.com/company/shakacode" />
        <meta itemProp="sameAs" content="https://github.com/shakacode" />
        <meta itemProp="sameAs" content="https://twitter.com/shakacode" />
        <meta itemProp="sameAs" content="https://www.facebook.com/shakacode/" />
        <meta itemProp="sameAs" content="https://www.crunchbase.com/organization/shakacode" />
      </span>
    </span>

    <span itemScope itemType="https://schema.org/WebSite">
      <meta itemProp="url" content="https://hichee.com" />
      <span itemProp="potentialAction" itemScope itemType="https://schema.org/SearchAction">
        <meta itemProp="target" content="https://hichee.com/vacation-rentals?location={query}" />
        <meta itemProp="query-input" content="name=query" />
      </span>
    </span>

    <ul itemScope itemType="http://www.schema.org/SiteNavigationElement" style={{ display: 'none' }}>
      <li itemProp="name">
        <a itemProp="url" href="https://hichee.com/">
          Home
        </a>
      </li>
      <li itemProp="name">
        <a itemProp="url" href="https://hichee.com/hosts">
          Boost Direct Bookings
        </a>
      </li>

      <li itemProp="name">
        <a itemProp="url" href="https://hichee.com/faq">
          FAQ
        </a>
      </li>

      <li itemProp="name">
        <a itemProp="url" href="https://hichee.com/about">
          About
        </a>
      </li>

      <li itemProp="name">
        <a itemProp="url" href="https://hichee.com/blog">
          Blog
        </a>
      </li>
    </ul>
  </>
);

export default WelcomePageStructuredData;
